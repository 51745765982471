import { ReactNode } from 'react';
import { Link, Tooltip } from '@mui/material';
import { Error, Visibility as VisibilityIcon } from '@mui/icons-material';

import {
  Environment,
  EntityPromotionsResponse,
  PromotionResponse,
  PromotionComplete,
  PromotionDefunct,
  PromotionInProgress,
  PromotionScheduled,
  PromotionStalled,
} from '@playq/octopus-common';
import { App, Space } from '@playq/octopus2-apps';

import { getSandboxPromotion, isPromotionLoading, createManifestLink } from '/shared/Promotions/helpers';

import { DefunctContainer, PromotionCompleteText } from './styles';
import { EntityID, EnvState } from './types';

export const getEntityLabels = (entityID: EntityID): { notification: string; link: string } | undefined => {
  switch (entityID.getClassName()) {
    case 'AppID':
      return {
        notification: 'Config',
        link: 'config',
      };
    case 'GameEventID':
      return {
        notification: 'Game event',
        link: 'gameevents',
      };
    case 'ExperimentID':
      return {
        notification: 'Experiment',
        link: 'experiments',
      };
    case 'FlowID':
      return {
        notification: 'Flow',
        link: 'flows',
      };
    case 'SynapseProblemID':
      return {
        notification: 'Synapse Problem',
        link: 'synapseproblem',
      };
    case 'ItemID':
      return {
        notification: 'Inventory',
        link: 'inventory',
      };
  }
};

export const getEnvStateText = (
  promotion: PromotionResponse,
  env: Environment,
  entityID: EntityID,
  manifestName?: string,
  app?: App,
  space?: Space
): ReactNode | string => {
  const { state, version: promotionVersion } = promotion;

  if (state instanceof PromotionInProgress) {
    return `${(state.progress * 100).toFixed(2)}%`;
  }

  if (state instanceof PromotionComplete) {
    const entityLinkName = manifestName || getEntityLabels(entityID)?.link;
    const manifestLink = createManifestLink(env, promotionVersion, entityID, entityLinkName, app, space);

    return (
      <PromotionCompleteText data-testid={`promoted-version-${env.toLowerCase()}`}>
        Version: {promotionVersion}
        {manifestLink && (
          <Link data-testid='visibility-icon' href={manifestLink} target='_blank' rel='noreferrer'>
            <VisibilityIcon fontSize='small' />
          </Link>
        )}
      </PromotionCompleteText>
    );
  }

  if (state instanceof PromotionScheduled) {
    return `Scheduled`;
  }

  if (state instanceof PromotionDefunct) {
    return (
      <DefunctContainer>
        Defunct!
        <Tooltip title={state.error} placement='top'>
          <Error data-testid='defunct-error-icon' />
        </Tooltip>
      </DefunctContainer>
    );
  }

  if (state instanceof PromotionStalled) {
    return `Stalled! Please, try again`;
  }

  return '';
};

export const getDefaultEnvState = (): EnvState => ({
  disablePromote: false,
  disableDemote: false,
  loading: false,
  version: 1,
  status: 'Nothing is deployed',
});

export const getSandboxState = (
  entityID: EntityID,
  manifestName?: string,
  promotions?: EntityPromotionsResponse,
  version?: number,
  app?: App,
  space?: Space
): EnvState => {
  const state = getDefaultEnvState();
  state.version = version ?? state.version;
  const sandboxPromotion = getSandboxPromotion(promotions, space);

  if (sandboxPromotion) {
    state.status = getEnvStateText(sandboxPromotion, Environment.Sandbox, entityID, manifestName, app, space);

    if (sandboxPromotion.state instanceof PromotionDefunct) {
      state.disablePromote = false;
      state.disableDemote = false;
    }

    if (!(sandboxPromotion.state instanceof PromotionComplete)) {
      state.disableDemote = true;
    }

    if (isPromotionLoading(sandboxPromotion)) {
      state.loading = true;
      state.disablePromote = true;
      state.disableDemote = true;
    }

    if (sandboxPromotion.state instanceof PromotionComplete && sandboxPromotion.version === version) {
      state.disablePromote = true;
    }
  } else {
    state.disableDemote = true;
  }

  if (promotions && isPromotionLoading(promotions.live)) {
    state.disablePromote = true;
    state.disableDemote = true;
  }

  return state;
};

export const getLiveState = (
  entityID: EntityID,
  manifestName?: string,
  promotions?: EntityPromotionsResponse,
  version?: number,
  app?: App,
  space?: Space
): EnvState => {
  const state = getDefaultEnvState();
  state.version = version ?? state.version;

  if (space) {
    state.disablePromote = true;
    state.disableDemote = true;
  }

  if (promotions) {
    const sandboxPromotion = getSandboxPromotion(promotions, space);

    if (sandboxPromotion) {
      if (sandboxPromotion.state instanceof PromotionComplete) {
        state.version = sandboxPromotion.version;

        if (
          promotions.live &&
          promotions.live.state instanceof PromotionComplete &&
          promotions.live.version === sandboxPromotion.version
        ) {
          state.disablePromote = true;
        }
      }

      if (isPromotionLoading(sandboxPromotion)) {
        state.disablePromote = true;
        state.disableDemote = true;
      }

      if (sandboxPromotion.state instanceof PromotionDefunct) {
        state.disablePromote = true;
      }
    } else {
      state.disablePromote = true;
    }

    if (promotions.live) {
      state.status = getEnvStateText(promotions.live, Environment.Live, entityID, manifestName, app, space);

      if (!(promotions.live.state instanceof PromotionComplete)) {
        state.disableDemote = true;
      }

      if (isPromotionLoading(promotions.live)) {
        state.loading = true;
        state.disablePromote = true;
        state.disableDemote = true;
      }
    } else {
      state.disableDemote = true;
    }
  } else {
    state.disablePromote = true;
    state.disableDemote = true;
  }

  return state;
};
